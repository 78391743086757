import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { Row, Col, Button, Input } from 'antd';
import { XCardsProfileBg, XAvatar, XUpload } from '../Profile/ProfileStyle';
import { XRadio } from '../CaseForm/CaseFormStyle';

const props = {
	action: '#',
	listType: 'picture',
	previewFile(file) {
		console.log('Your upload file:', file);
		// Your process logic. Here we just mock to the same file
		return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
			method: 'POST',
			body: file,
		})
			.then((res) => res.json())
			.then(({ thumbnail }) => thumbnail);
	},
};

const ResetAccount = (props) => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const [value, setValue] = useState(1);
	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};
	return (
		<>
			<HeaderFile />
			<XContainer fluid>
				<XCardsProfileBg>
					<div className="profileHead"></div>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
					>
						<div className="innerBody mt-4">
							<Row gutter={(15, 30)}>
								<Col
									xs={{ span: 24, offset: 0 }}
									sm={{ span: 24, offset: 0 }}
									lg={{ span: 8, offset: 8 }}
								>
									<Row className="mb-5 pb-5">
										<Col xs={24}>
											<XForm.Item
												name="new_password"
												label="New Password*"
											>
												<Input
													size="large"
													placeholder="Enter your password"
													name="new_password"
												/>
											</XForm.Item>
										</Col>
										<Col xs={24}>
											<XForm.Item
												name="confirm_new_password"
												label="Confirm New Password*"
											>
												<Input
													size="large"
													placeholder="Confirm new password"
													name="confirm_new_password"
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} className="text-center">
											<Button
												onClick={() => navigate(-1)}
												className="cancel"
											>
												Cancel
											</Button>
											<Button type="primary">
												Submit
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</XForm>
				</XCardsProfileBg>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResetAccount);
