import { Button, Card } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Container from 'react-bootstrap/Container';
import {
	Layout,
	Table,
	Tag,
	Dropdown,
	Modal,
	Form,
	Select,
	Switch,
} from 'antd';
export const Theme = {
	colors: {
		white: '#fff',
		menu1: '#a7a7a7',
		menu2: '#212226',
		color1: '#757575',
		color2: '#081D16',
		color3: '#d0e0df',
		color4: '#7f8e89',
		color5: '#f8f8f8',
		color6: '#dadee3',
		color7: '#11263c',
		color8: '#868FA0',
		color9: '#687182',
		fontPoppins: "'Poppins', sans-serif !important ",
		fontDM: "'DM Sans', sans-serif !important ",
		fontInter: "'Inter', sans-serif !important ",
	},
	media: { mobile: '768px', tab: '998px' },
};
export const XLayout = styled(Layout)`
	background: none !important;
	margin-bottom: 15px;
`;
export const XContainer = styled(Container)`
	padding: 0px 60px;
	@media only screen and (max-width: 768.99px) {
		padding: 0px 15px;
	}
	@media only screen and (min-width: 1920.99px) {
		width: 1800px;
	}
`;
export const XButton = styled(Button)`
	width: 100%;
	border-radius: 50px !important;
	background: ${({ theme }) => theme.colors.menu2};
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.colors.fontDM};
	padding: 10px 0px;
	height: auto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.28px;
	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XButton2 = styled(Button)`
	width: 100%;
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	padding: 12px 0px;
	height: auto !important;
	border-radius: 20px;
	border: 1px solid ${({ theme }) => theme.colors.color6};
	background: ${({ theme }) => theme.colors.color5};
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.28px;

	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XCardsTransparent = styled(Card)`
	border-radius: 20px !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	background: rgba(255, 255, 255, 0.7) !important;
	.ant-card-body {
		padding: 30px !important;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XTable = styled(Table)`
	.ant-table {
		background: transparent;
	}
	.ant-table-thead > tr > th {
		background: transparent !important;
		color: ${({ theme }) => theme.colors.color4};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 16px;
		text-transform: uppercase;
		border-top: 2px solid rgba(0, 0, 0, 0.05);
		border-radius: 0px !important;
		&::before {
			display: none;
		}
	}
	.ant-table-tbody > tr > td {
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		border-bottom: 0px;
		color: ${({ theme }) => theme.colors.color7};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.42px;
	}
	.showDate img {
		margin-right: 10px;
		margin-top: -3px;
	}
`;
export const XTag = styled(Tag)`
	border-radius: 4px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.36px;
	padding-left: 20px;
	border: none;
	&:before {
		content: '■';
		position: absolute;
		top: -1px;
		left: 5px;
		font-size: 10px;
	}
`;
export const XDropdown = styled(Dropdown)`
	text-align: center;
	color: ${({ theme }) => theme.colors.color8};
	font-size: 26px;
	font-weight: 600;
`;
export const XConfirmModal = styled(Modal)``;

export const XForm = styled(Form)`
	label {
		color: ${({ theme }) => theme.colors.color7} !important;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 500 !important;
		line-height: normal;
	}
	input {
		border: 1px solid ${({ theme }) => theme.colors.color3};
		background: transparent;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		@media only screen and (max-width: 768.99px) {
		}
	}
	button {
		display: inline-block;
		margin-left: 15px;
		border-radius: 20px !important;
		background: ${({ theme }) => theme.colors.menu2};
		color: #fff;
		font-size: 14px !important;
		padding: 10px 30px;
		height: auto;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		border: 1px solid ${({ theme }) => theme.colors.color6};
		&:hover {
			background: ${({ theme }) => theme.colors.menu2} !important;
			color: #fff !important;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
	button.cancel {
		background: ${({ theme }) => theme.colors.white};
		color: #171c26;
	}
	.customLabel {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
	}
	.customHeader {
		color: ${({ theme }) => theme.colors.menu2};
		background: #f3f6f9;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
		padding: 5px 15px;
	}
	.ant-switch {
		padding: 0px 5px;
		background: transparent;
		border: 1px solid ${({ theme }) => theme.colors.menu2};

		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.menu2};
			}
		}
	}
	button.ant-switch {
		&:hover {
			background: ${({ theme }) => theme.colors.menu2};
			.ant-switch-handle {
				&::before {
					background: ${({ theme }) => theme.colors.white};
				}
			}
		}
	}
	button.ant-switch-checked {
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
`;
export const XSelect = styled(Select)`
	.ant-select-selector {
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		background: transparent !important;
		box-shadow: none;
		height: 45px !important;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 45px;
	}
	@media only screen and (min-width: 768.99px) {
	}
`;
export const XModal = styled(Modal)`
	.ant-modal-title {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.36px;
	}
	label {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-weight: 500 !important;
	}
	.modalButton {
		width: 100%;
		position: relative;
		margin: 0px;
		display: block;
		color: ${({ theme }) => theme.colors.white};
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.36px;
	}
	.ant-modal-content {
		border-radius: 20px !important;
	}
	.ant-modal-footer {
		.ant-btn {
			border-radius: 20px;
			height: 40px;
			padding: 0px 30px;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.28px;
		}
		.ant-btn-primary {
			border: 1px solid ${({ theme }) => theme.colors.color6};
			background: ${({ theme }) => theme.colors.menu2};
			&:hover {
				border: 1px solid ${({ theme }) => theme.colors.color6};
				background: ${({ theme }) => theme.colors.menu2};
			}
		}
		.ant-btn-default {
			border-color: ${({ theme }) => theme.colors.color6};
			background: ${({ theme }) => theme.colors.white};
			box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32),
				0px 5px 5px 0px rgba(0, 0, 0, 0.1);
			color: ${({ theme }) => theme.colors.menu2};
			&:hover {
				border-color: ${({ theme }) => theme.colors.menu2};
				background: ${({ theme }) => theme.colors.menu2};
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}
`;
export const XSwitch = styled(Switch)``;
export const XCardsCaseBg = styled(Card)`
	min-height: 600px;
	border-radius: 20px;
	border: none;
	.ant-card-body {
		min-height: 600px;
		border-radius: 20px;
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		padding: 30px !important;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
	}
`;
export const GlobalStyle = createGlobalStyle`
	${'' /* background-color: ${Theme.colors.bg}; */}
	@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Poppins', sans-serif !important;
		${'' /* font-family: 'DM Sans', sans-serif; */}
	}
	body{
		background: transparent url(../images/body-bg.svg) no-repeat center center;
		background-attachment: fixed;
		background-size: cover;
		font-family: 'Poppins', sans-serif !important;
	}
	a, p{
		font-family: 'Poppins', sans-serif !important;
		font-weight:normal;
		text-decoration:none;
	}
	.offcanvas-header{
		border-bottom:1px solid #CCC;
	}
	.offcanvas.offcanvas-top{
		height:100vh;
		transition:transform 0.3s ease-in-out !important;
		z-index:1050;
		@media only screen and (max-width: 1199.99px) {
			padding-top:60px;
		}
		
		${
			'' /* height:100vh;
		transition: mobileNavEffect 1s normal forwards ease-in-out !important;
		animation: mobileNavEffect .3s ease-in-out;		
		@keyframes mobileNavEffect {
			100% {
				opacity: 1;
			}

			50% {
				opacity: 0.6;
				transform: scale3d(.1, .1, .1);
			}
			25% {
				opacity: 0.3;
				transform: scale3d(.1, .1, .1);
			}
			0% {
				opacity: 0;
			}
		} */
		}
	}
	
	.marginTopMobile {
		@media only screen and (max-width: 1199.99px) {
			margin-top: 80px !important;
		}
	}
	.nav-link {
		color: ${({ theme }) => theme.colors.menu1};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		&:hover {
			color: ${({ theme }) => theme.colors.menu2} !important;
			font-weight: 600;
		}
	}
	.nav-link.active {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}
	.ant-modal-confirm {
		.ant-btn{
			&:hover{
				border-color: ${({ theme }) => theme.colors.menu2} !important;
			}
		}
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.menu2};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.menu2};
			border-color: ${({ theme }) => theme.colors.color3};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}
	.ant-select.ant-select-in-form-item,
	.ant-input,
	.ant-picker,
	.ant-select-selector,
	.ant-input-affix-wrapper {
		background: transparent !important;
	}
	span.completed {
		background: #e1fcef !important;
		color: #14804a !important;
	}
	span.pending {
		background: #FDE9CB !important;
		color: #E78900 !important;
	}
	span.ReAllocated {
		background: #F1EFFF !important;
		color: #6654F1 !important;
	}
	.textArea{
		border-radius: 20px;
		border: 1px solid rgba(0, 0, 0, 0.10);
		background: ${({ theme }) => theme.colors.white} !important;
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		padding:10px 15px !important;
	}
	.iconResp{
		width:40px;
	}
	p.modalPara1{
		color: rgba(33, 34, 38, 0.60);
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}
	.confirmModal {
		.ant-btn{
			border-radius: 25px !important;	
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.menu2};
		};
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
		};

	}
`;
