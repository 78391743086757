import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button, DatePicker } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { XCardsTransparent, XForm } from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { XCardBackBg, XButtonBack, XEditTitle, XRadio } from './CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

const { TextArea } = Input;

const Manage = () => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const id = useParams().id;

	const [value, setValue] = useState(1);
	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};
	const onDateChange = (date, dateString) => {
		console.log(date, dateString);
	};
	const onSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		console.log('Change:', e.target.value);
	};
	return (
		<>
			<HeaderFile activeNav="dashboard" />

			<XContainer fluid>
				<XCardBackBg className="mb-3">
					<Row>
						<Col xs={24}>
							<XButtonBack
								onClick={() => navigate(-1)}
								icon={<ArrowLeftOutlined />}
								size="large"
							/>
							{id ? (
								<XEditTitle>Edit Case</XEditTitle>
							) : (
								<XEditTitle>Add Case</XEditTitle>
							)}
						</Col>
					</Row>
				</XCardBackBg>

				<XCardsTransparent>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
					>
						<Row gutter={(15, 30)}>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item
									name="patient_name"
									label="Patient Name*"
								>
									<Input
										size="large"
										placeholder="Enter patient name"
										name="patient_name"
									/>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item name="gender" label="Gender*">
									<XRadio.Group
										onChange={onChange}
										value={value}
										name="gender"
									>
										<XRadio value={1}>Male</XRadio>
										<XRadio value={2}>Female</XRadio>
										<XRadio value={3}>Other</XRadio>
									</XRadio.Group>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item
									name="treatment_date"
									label="Treatment Date*"
								>
									<DatePicker
										className="w-100"
										size="large"
										name="treatment_date"
										onChange={onDateChange}
									/>
								</XForm.Item>
							</Col>
							<Col xs={24}>
								<XForm.Item
									name="summery"
									label="Enter Clinical Summery*"
								>
									<TextArea
										name="summery"
										showCount
										maxLength={100}
										style={{
											height: 120,
											marginBottom: 24,
										}}
										onChange={onSummaryChange}
										placeholder="can resize"
									/>
								</XForm.Item>
							</Col>
						</Row>
						<Row className="mt-4">
							<Col xs={24} className="text-end">
								<Button
									onClick={() => navigate(-1)}
									className="cancel"
								>
									Cancel
								</Button>
								<Button type="primary">Submit</Button>
							</Col>
						</Row>
					</XForm>
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

export default Manage;
