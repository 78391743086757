import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Logo, Title, XForm, XLottie } from '../Login/LoginStyle';
import { CenterWrapper, XPara1 } from '../Forgot/ForgotStyle';
import { Button, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { XContainer } from '../../styles/GlobalStyle';

const Reset = (props) => {
	const [form] = XForm.useForm();
	const navigate = useNavigate();
	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Logo>
						<img
							className="img-fluid"
							src="./images/logo.svg"
							alt="logo"
						/>
					</Logo>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						<Title className="mb-2">Reset Password</Title>
						<XPara1 className="mb-4 mb-lg-5 text-left">
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua.
						</XPara1>

						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
						>
							<XForm.Item name="nPassword" label="New Password">
								<Input
									size="large"
									placeholder="Enter new password"
								/>
							</XForm.Item>

							<XForm.Item
								name="cPassword"
								label="Confirm Password"
							>
								<Input
									size="large"
									placeholder="Confirm your password"
								/>
							</XForm.Item>

							<XForm.Item className="mt-5">
								<Button onClick={() => navigate('/')}>
									Log In
								</Button>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
