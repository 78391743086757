import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button } from 'antd';
import { XButton2 } from '../../styles/GlobalStyle';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
	Title,
	XAutoComplete,
	Title2,
	XForm,
	XSelect,
} from './CaseSearchStyle';

const getRandomInt = (max, min = 0) =>
	Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
	new Array(getRandomInt(5))
		.join('.')
		.split('.')
		.map((_, idx) => {
			const category = `${query}${idx}`;
			return {
				value: category,
				label: (
					<>
						Found {query} on {category}
					</>
				),
			};
		});

const CaseSearch = (props) => {
	const navigate = useNavigate();
	const [options, setOptions] = useState([]);
	const handleSearch = (value) => {
		setOptions(value ? searchResult(value) : []);
	};
	const onSelect = (value) => {
		console.log('onSelect', value);
	};
	const { Option } = XSelect;
	return (
		<>
			<XForm name="search">
				<Row gutter={(15, 30)}>
					{props.searchOption === 'default' && (
						<>
							<Col xs={24} sm={12} lg={9}>
								<Row className="align-items-center">
									<Col xs={24} sm={12} lg={8}>
										<XForm.Item>
											<Title>Case Allocation</Title>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} lg={16}>
										<XForm.Item>
											<XAutoComplete
												popupMatchSelectWidth={252}
												options={options}
												onSelect={onSelect}
												onSearch={handleSearch}
												size="large"
											>
												<Input
													size="large"
													placeholder="Search for cases"
												/>
											</XAutoComplete>
										</XForm.Item>
									</Col>
								</Row>
							</Col>
							<Col xs={24} sm={12} lg={6}>
								<XForm.Item
									name="categories"
									colon={false}
									label="Categories"
								>
									<XSelect size="large" defaultValue="1">
										<Option value="1">All</Option>
										<Option value="2">Categories 1</Option>
										<Option value="3">Categories 2</Option>
										<Option value="4">Categories 3</Option>
									</XSelect>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} lg={6}>
								<XForm.Item
									name="posted"
									colon={false}
									label="Posted"
								>
									<XSelect size="large" defaultValue="1">
										<Option value="1">Last Month</Option>
										<Option value="2">2 Month</Option>
										<Option value="3">3 Month</Option>
										<Option value="4">4 Month</Option>
									</XSelect>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} lg={3}>
								<XForm.Item className="text-center">
									<XButton2
										onClick={() => navigate('/case/add')}
										icon={<PlusOutlined />}
									>
										New Case
									</XButton2>
								</XForm.Item>
							</Col>
						</>
					)}
					{props.searchOption === 'userManagement' && (
						<>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 9, offset: 0 }}
								xl={{ span: 9, offset: 0 }}
							>
								<Row className="align-items-center">
									<Col xs={24} sm={12} lg={4}>
										<XForm.Item>
											<Title>Users</Title>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} lg={20}>
										<XForm.Item>
											<XAutoComplete
												popupMatchSelectWidth={252}
												options={options}
												onSelect={onSelect}
												onSearch={handleSearch}
												size="large"
											>
												<Input
													size="large"
													placeholder="Search user"
												/>
											</XAutoComplete>
										</XForm.Item>
									</Col>
								</Row>
							</Col>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 4, offset: 11 }}
								xl={{ span: 3, offset: 12 }}
							>
								<XForm.Item className="text-right">
									<XButton2
										// onClick={() => navigate('/case/add')}
										icon={<PlusOutlined />}
									>
										New Case
									</XButton2>
								</XForm.Item>
							</Col>
						</>
					)}
					{props.searchOption === 'roleManagement' && (
						<>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 9, offset: 0 }}
								xl={{ span: 9, offset: 0 }}
							>
								<XForm.Item>
									<Title>Roles</Title>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 4, offset: 11 }}
								xl={{ span: 3, offset: 12 }}
							>
								<XForm.Item className="text-right">
									<XButton2
										// onClick={() => navigate('/case/add')}
										icon={<PlusOutlined />}
									>
										New Case
									</XButton2>
								</XForm.Item>
							</Col>
						</>
					)}
					{props.searchOption === 'groupManagement' && (
						<>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 9, offset: 0 }}
								xl={{ span: 9, offset: 0 }}
							>
								<XForm.Item>
									<Title>Groups</Title>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								sm={{ span: 12, offset: 0 }}
								lg={{ span: 4, offset: 11 }}
								xl={{ span: 3, offset: 12 }}
							>
								<XForm.Item className="text-right">
									<XButton2
										// onClick={() => navigate('/case/add')}
										icon={<PlusOutlined />}
									>
										New Case
									</XButton2>
								</XForm.Item>
							</Col>
						</>
					)}
					{props.searchOption === 'casePreparation' && (
						<>
							<Col xs={24} sm={12} lg={9}>
								<Row className="align-items-center">
									<Col xs={24} sm={12} lg={6}>
										<XForm.Item>
											<Title>Case</Title>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} lg={18}>
										<XForm.Item>
											<XAutoComplete
												popupMatchSelectWidth={252}
												options={options}
												onSelect={onSelect}
												onSearch={handleSearch}
												size="large"
											>
												<Input
													size="large"
													placeholder="Search for cases"
												/>
											</XAutoComplete>
										</XForm.Item>
									</Col>
								</Row>
							</Col>
							<Col xs={24} sm={12} lg={6}>
								<XForm.Item
									name="categories"
									colon={false}
									label="Categories"
								>
									<XSelect size="large" defaultValue="1">
										<Option value="1">All</Option>
										<Option value="2">Categories 1</Option>
										<Option value="3">Categories 2</Option>
										<Option value="4">Categories 3</Option>
									</XSelect>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} lg={6}>
								<XForm.Item
									name="posted"
									colon={false}
									label="Posted"
								>
									<XSelect size="large" defaultValue="1">
										<Option value="1">Last Month</Option>
										<Option value="2">2 Month</Option>
										<Option value="3">3 Month</Option>
										<Option value="4">4 Month</Option>
									</XSelect>
								</XForm.Item>
							</Col>
						</>
					)}
				</Row>
			</XForm>
		</>
	);
};

export default CaseSearch;
