import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import {
	XNavbar,
	XSpace,
	XDropdown,
	XAvatar,
	XDownOutlined,
	XBellOutlined,
	XSpaceNotif,
} from './HeaderStyle';
import { MenuOutlined, CloseOutlined, SmileOutlined } from '@ant-design/icons';
import { XContainer } from '../../styles/GlobalStyle';

const items = [
	{
		key: '1',
		label: <Link to="/profile">My Profile</Link>,
		icon: (
			<img className="img-fluid" src="/images/profile-icon.svg" alt="" />
		),
	},
	{
		type: 'divider',
	},
	{
		key: '2',
		label: <Link to="/reset-account">Reset Password</Link>,
		icon: <img className="img-fluid" src="/images/reset.svg" alt="" />,
	},
	{
		type: 'divider',
	},
	{
		key: '3',
		label: <Link to="/login">Log Out</Link>,
		icon: <img className="img-fluid" src="/images/logout.svg" alt="" />,
	},
];

const HeaderFile = (props) => {
	const [hamberToggle, setHamberToggle] = useState(false);
	const toggleHamburger = () => {
		setHamberToggle(!hamberToggle);
	};
	return (
		<div className="marginTopMobile">
			<XNavbar expand={'xl'} className="mb-3">
				<XContainer fluid>
					<Navbar.Brand href="/">
						<img
							className="img-fluid"
							src="/images/logo.svg"
							alt=""
						/>
					</Navbar.Brand>

					<Navbar.Toggle
						onClick={toggleHamburger}
						// aria-controls={`offcanvasNavbar-expand-xl`}
					>
						{hamberToggle ? <CloseOutlined /> : <MenuOutlined />}
					</Navbar.Toggle>

					<Navbar.Offcanvas
						id={`offcanvasNavbar-expand-xl`}
						aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
						placement="top"
						visible={true}
					>
						<Offcanvas.Body>
							<Nav className="justify-content-center centerMenuSpace flex-grow-1">
								<Link
									className={
										props.activeNav === 'dashboard'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/"
								>
									Dashboard
								</Link>
								<Link
									className={
										props.activeNav === 'manage'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/manage"
								>
									Manage
								</Link>
								<Link
									className={
										props.activeNav === 'caseAllocation'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/case-allocation"
								>
									Case Allocation
								</Link>
								<Link
									className={
										props.activeNav === 'analyseCoverage'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/analyse-coverage"
								>
									Analyse Coverage
								</Link>
								<Link
									className={
										props.activeNav === 'casePreparation'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/case-preparation"
								>
									Case Preparation
								</Link>
								{/* <Link
									className="nav-link"
									to="/role-assignment"
								>
									Role Assignment
								</Link> */}
							</Nav>
							<Nav className="justify-content-end flex-grow-2">
								<XDropdown
									menu={{
										items,
									}}
									placement="bottom"
								>
									<XSpace>
										<XAvatar
											size={30}
											src="/images/profile.png"
										/>
										<XDownOutlined />
									</XSpace>
								</XDropdown>
								<XDropdown
									menu={{
										items,
									}}
									placement="bottom"
								>
									<XSpaceNotif>
										<XBellOutlined />
									</XSpaceNotif>
								</XDropdown>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</XContainer>
			</XNavbar>
		</div>
	);
};

export default HeaderFile;
