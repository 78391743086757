import { React, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Button, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XTable,
	XSelect,
	XForm,
	XModal,
	XButtonModal,
	XTag,
} from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';

const onChange = (key) => {
	console.log(key);
};
const showDeleteConfirm = () => {
	XModal.confirm({
		title: 'Are you sure delete this case?',
		icon: <ExclamationCircleFilled />,
		//content: 'Some descriptions',
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'No',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const All = (props) => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const { Option } = XSelect;
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const columns = [
		{
			title: 'Patient Name',
			dataIndex: 'patientName',
		},
		{
			title: 'Allocated to',
			dataIndex: 'allocated',
		},
		{
			title: 'status',
			dataIndex: 'status',
		},
		{
			title: 'last date',
			dataIndex: 'lastdate',
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			width: '10%',
		},
	];
	const data = [];
	for (let i = 1; i < 7; i++) {
		data.push({
			key: i,
			patientName: `Robert Anthony`,
			allocated: `Amin Sayyed`,
			status: (
				<>
					{i === 1 || i === 4 ? (
						<XTag className="completed">Completed</XTag>
					) : (
						''
					)}
					{i === 2 || i === 5 ? (
						<XTag className="pending">Pending</XTag>
					) : (
						''
					)}
					{i === 3 || i === 6 ? (
						<XTag className="ReAllocated">Re-allocated</XTag>
					) : (
						''
					)}
				</>
			),
			lastdate: (
				<div className="showDate">
					<img
						className="img-fluid"
						src="./images/date-icon.svg"
						alt=""
					/>
					04 Oct 2023 , 18:00 PM
				</div>
			),
			action: (
				<div className="text-center">
					<Row>
						<Col xs={12}>
							<XButtonImg
								onClick={() => setModalOpen(true)}
								type="text"
							>
								<EditOutlined />
							</XButtonImg>
						</Col>
						<Col xs={12}>
							<XButtonImg onClick={showDeleteConfirm} type="text">
								<DeleteOutlined />
							</XButtonImg>
						</Col>
					</Row>
				</div>
			),
		});
	}
	return (
		<>
			<XTable
				rowSelection={rowSelection}
				columns={columns}
				dataSource={data}
				scroll={{ x: 991 }}
				pagination={false}
			/>
			<XModal
				title="Edit Patient Details"
				centered
				open={modalOpen}
				onOk={() => setModalOpen(false)}
				onCancel={() => setModalOpen(false)}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<XForm.Item
								name="patient_name"
								label="Patient Name*"
							>
								<Input
									size="large"
									placeholder="Enter patient name"
									name="patient_name"
								/>
							</XForm.Item>
						</Col>
						<Col xs={24}>
							<XForm.Item
								name="allocated_to"
								label="Allocated to*"
							>
								<XSelect size="large" defaultValue="1">
									<Option value="1">Group 1</Option>
									<Option value="2">Group 2</Option>
									<Option value="3">Group 3</Option>
									<Option value="4">Group 4</Option>
								</XSelect>
							</XForm.Item>
						</Col>
						<Col xs={24}>
							<XForm.Item name="email_id" label="Email ID*">
								<Input
									size="large"
									placeholder="Enter email id"
									name="email_id"
								/>
							</XForm.Item>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<Button
								onCancel={() => setModalOpen(false)}
								className="modalButton"
							>
								Submit
							</Button>
						</Col>
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(All);
