import { React, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal } from 'antd';
import { Flex, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { XTable, XModal, XForm, XSwitch } from '../../styles/GlobalStyle';
import { XButtonImg } from './ManageStyle';
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';

const onChange = (key) => {
	console.log(key);
};
const showDeleteConfirm = () => {
	Modal.confirm({
		title: 'Are you sure delete this case?',
		icon: <ExclamationCircleFilled />,
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'No',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const RoleManagement = (props) => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const columns = [
		{
			title: 'Role',
			dataIndex: 'role',
		},
		{
			title: 'created on',
			dataIndex: 'createdOn',
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			width: '10%',
		},
	];
	const data = [];
	for (let i = 1; i < 7; i++) {
		data.push({
			key: i,
			role: `Super Admin`,
			createdOn: `04 Oct 2023, 12:00 PM`,
			action: (
				<div className="text-center">
					<Row>
						<Col xs={12}>
							<XButtonImg
								onClick={() => setModalOpen(true)}
								type="text"
							>
								<EditOutlined />
							</XButtonImg>
						</Col>
						<Col xs={12}>
							<XButtonImg onClick={showDeleteConfirm} type="text">
								<DeleteOutlined />
							</XButtonImg>
						</Col>
					</Row>
				</div>
			),
		});
	}
	return (
		<>
			<XTable
				rowSelection={rowSelection}
				columns={columns}
				dataSource={data}
				scroll={{ x: 991 }}
				pagination={false}
			/>
			<XModal
				title="Edit Role"
				centered
				open={modalOpen}
				onOk={() => setModalOpen(false)}
				onCancel={() => setModalOpen(false)}
				className="modalButtonBoth"
				okText="Submit"
				zIndex={1100}
				width={800}
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24} lg={12}>
							<XForm.Item
								name="role_name"
								label="Enter Role Name*"
							>
								<Input
									size="large"
									placeholder="Enter Role Name"
									name="role_name"
								/>
							</XForm.Item>
						</Col>
					</Row>
					<Row>
						<Col xs={24}>
							<div className="customLabel mb-2">Give Access</div>
						</Col>
						<Col xs={24}>
							<div className="customHeader">
								<Flex
									justify="space-between"
									align="flex-start"
								>
									<span>Lorem Ipsum</span>
									<span>
										<XSwitch
											defaultChecked
											onChange={onChange}
											size="small"
										/>
									</span>
								</Flex>
							</div>
						</Col>
					</Row>
					<Row gutter={(15, 20)} className="mt-4">
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
						<Col sm={24} lg={6} className="mb-4">
							Lorem Ipsum
							<XSwitch size="small" />
						</Col>
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
