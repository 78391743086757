import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Input, Space, Divider } from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import Thanks from '../../json/thanks.json';
import { XContainer, XForm, XModal } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XDivider,
	XButtonLike,
} from './AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	CopyOutlined,
	MailOutlined,
} from '@ant-design/icons';

const { TextArea } = Input;

const showDeleteConfirm = () => {
	XModal.confirm({
		title: 'Delete Case?',
		content: (
			<p className="modalPara1">
				Are you sure you want to delete this case?
			</p>
		),
		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
		okText: 'Yes, confirm',
		okType: 'danger',
		cancelText: 'No, cancel',
		className: 'confirmModal',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const AnalyseCoverage = (props) => {
	const [form] = XForm.useForm();
	const navigate = useNavigate();
	const [modalOpen, setEditModalOpen] = useState(false);
	const [thanksModal, setThanksModal] = useState(false);

	return (
		<>
			<HeaderFile activeNav="analyseCoverage" />
			<XContainer fluid>
				{/* Remove d-none class */}
				<div id="getQust" className="d-none">
					<Row>
						<Col
							xs={{ span: 24, offset: 0 }}
							lg={{ span: 6, offset: 9 }}
							className="text-center"
						>
							<XLottieMargin>
								<XLottie
									animationData={LoginAnimation}
									loop={true}
								/>
							</XLottieMargin>
						</Col>
					</Row>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
					>
						<Row>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 8, offset: 8 }}
							>
								<Title>DeCodeX</Title>
							</Col>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 20, offset: 2 }}
								className="my-4"
							>
								<XPara1 className="text-center">
									DeCodeX utilizes comprehensive standardized
									clinical notes to generate recommended
									best-fit diagnostic ICD-10, procedural
									CPT/HCPCS or drug NDC codes. It also
									provides insights into Medicare coverage.
								</XPara1>
							</Col>
							<Col xs={{ span: 24 }}>
								<TextArea
									name="summery"
									showCount
									maxLength={1000}
									style={{
										height: 120,
										marginBottom: 24,
									}}
									className="textArea mt-2"
									placeholder="Enter Clinical Summary"
								/>
							</Col>
							<Col xs={24} className="text-center">
								<Button type="primary">Shazaam !!</Button>
							</Col>
						</Row>
					</XForm>
				</div>
				<div id="getAns" className="mt-4">
					<XCardsGrdBG>
						<Row className="align-items-center">
							<Col xs={20} lg={22}>
								<XPara1>
									81M w/ well-controlled hypertension,
									hyperlipidemia and DM2 is prescribed a home
									blood glucose monitor and test strips by his
									primary care physician.
								</XPara1>
							</Col>
							<Col xs={4} lg={2} className="text-end">
								<Button shape="circle" type="text">
									<img
										className="img-fluid"
										src="/images/reload-icon.svg"
										alt=""
									/>
								</Button>
							</Col>
						</Row>
					</XCardsGrdBG>
					<XCardsCaseBg className="mt-4">
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/past-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color1">
											Past Clinical History
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
								></XButtonAdd>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24} lg={20}>
								<XPara1>
									The patient is an 81-year-old male
								</XPara1>
							</Col>
							<Col xs={24} lg={4} className="text-end">
								<XButtonImg
									type="text"
									onClick={() => setEditModalOpen(true)}
								>
									<EditOutlined />
								</XButtonImg>
								<XButtonImg
									type="text"
									onClick={showDeleteConfirm}
								>
									<DeleteOutlined />
								</XButtonImg>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24} lg={20}>
								<XPara1>
									History of well-controlled hypertension,
									hyperlipidemia, and type 2 diabetes mellitus
									(DM2)
								</XPara1>
							</Col>
							<Col xs={24} lg={4} className="text-end">
								<XButtonImg
									type="text"
									onClick={() => setEditModalOpen(true)}
								>
									<EditOutlined />
								</XButtonImg>
								<XButtonImg
									type="text"
									onClick={showDeleteConfirm}
								>
									<DeleteOutlined />
								</XButtonImg>
							</Col>
						</Row>

						<XDivider dashed="true" />

						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/present-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color2">
											Present Complaint
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
								></XButtonAdd>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24} lg={20}>
								<XPara1>
									The patient has been prescribed a home blood
									glucose monitor
								</XPara1>
							</Col>
							<Col xs={24} lg={4} className="text-end">
								<XButtonImg
									type="text"
									onClick={() => setEditModalOpen(true)}
								>
									<EditOutlined />
								</XButtonImg>
								<XButtonImg
									type="text"
									onClick={showDeleteConfirm}
								>
									<DeleteOutlined />
								</XButtonImg>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24} lg={20}>
								<XPara1>
									Test strips by his primary care physician
								</XPara1>
							</Col>
							<Col xs={24} lg={4} className="text-end">
								<XButtonImg
									type="text"
									onClick={() => setEditModalOpen(true)}
								>
									<EditOutlined />
								</XButtonImg>
								<XButtonImg
									type="text"
									onClick={showDeleteConfirm}
								>
									<DeleteOutlined />
								</XButtonImg>
							</Col>
						</Row>

						<XDivider dashed="true" />

						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/procedure-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color3">
											Recommended Procedure
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
								></XButtonAdd>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24} lg={20}>
								<XPara1>
									The recommended procedure is regular home
									monitoring of blood glucose levels using the
									prescribed monitor and test strips.
								</XPara1>
							</Col>
							<Col xs={24} lg={4} className="text-end">
								<XButtonImg
									type="text"
									onClick={() => setEditModalOpen(true)}
								>
									<EditOutlined />
								</XButtonImg>
								<XButtonImg
									type="text"
									onClick={showDeleteConfirm}
								>
									<DeleteOutlined />
								</XButtonImg>
							</Col>
						</Row>

						<XDivider dashed="true" />

						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/coverage-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color4">
											Recommended Procedure
										</span>
									</XTitleHead>
								</Space>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									This procedure is covered under Medicare
									policy
								</XPara1>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									According to the National Coverage
									Determination (NCD) manual, under NCD code
									40.2
								</XPara1>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									home blood glucose monitors are covered for
									patients with diabetes as they are
									considered reasonable and necessary for the
									diagnosis and treatment of illness or injury
									or to improve the functioning of a malformed
									body member.
								</XPara1>
							</Col>
						</Row>

						<XDivider dashed="true" />

						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/cross-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color5">
											Recommended Procedure
										</span>
									</XTitleHead>
								</Space>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									This procedure is covered under Medicare
									policy
								</XPara1>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									According to the National Coverage
									Determination (NCD) manual, under NCD code
									40.2
								</XPara1>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									home blood glucose monitors are covered for
									patients with diabetes as they are
									considered reasonable and necessary for the
									diagnosis and treatment of illness or injury
									or to improve the functioning of a malformed
									body member.
								</XPara1>
							</Col>
						</Row>
					</XCardsCaseBg>

					<Row className="my-4 text-end">
						<Col xs={24}>
							<Space>
								<XButtonLike
									type="primary"
									shape="round"
									icon={<MailOutlined />}
									size="large"
								>
									Email
								</XButtonLike>
								<XButtonLike
									type="primary"
									shape="round"
									icon={<CopyOutlined />}
									size="large"
								>
									Copy Text
								</XButtonLike>
								<XButtonLike
									type="primary"
									shape="round"
									icon={
										<img
											src="/images/download-icon.svg"
											alt=""
										/>
									}
									size="large"
								>
									Download
								</XButtonLike>
								<Divider type="vertical" />
								<XButtonLike
									type="primary"
									shape="circle"
									icon={
										<img
											className="rotateButton"
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
								/>
								<XButtonLike
									onClick={() => setThanksModal(true)}
									type="primary"
									shape="circle"
									icon={
										<img
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
								/>
							</Space>
						</Col>
					</Row>
				</div>
			</XContainer>

			<XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">Edit</span>
					</>
				}
				centered
				open={modalOpen}
				onOk={() => setEditModalOpen(false)}
				onCancel={() => setEditModalOpen(false)}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<TextArea
								name="response"
								showCount
								maxLength={1000}
								style={{
									height: 120,
									marginBottom: 24,
								}}
								className="textArea mt-2"
								placeholder="Enter"
							/>
						</Col>
					</Row>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal}
				onOk={() => setThanksModal(false)}
				onCancel={() => setThanksModal(false)}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">
							Thanks for making us better
						</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseCoverage);
