import { React } from 'react';
import { connect } from 'react-redux';
import {
	XContainer,
	XCardsCaseBg,
	XButton2,
	XForm,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { Row, Col, Space } from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import {
	XcalendarBtn,
	XFilterSelect,
	XFilterInput,
	XTabs,
} from './CaseAllocationStyle';
import { useNavigate } from 'react-router-dom';
import All from './All';

const { Option } = XFilterSelect;

const prefixSelector = (
	<XForm.Item name="prefix" noStyle>
		<img className="img-fluid me-2" src="/images/filter-icon.svg" alt="" />
		<XFilterSelect
			size="large"
			defaultValue="0"
			suffixIcon={<DownOutlined />}
		>
			<Option value="0">All</Option>
			<Option value="1">Filter 1</Option>
			<Option value="2">Filter 2</Option>
			<Option value="3">Filter 3</Option>
			<Option value="4">Filter 4</Option>
			<Option value="5">Filter 5</Option>
		</XFilterSelect>
	</XForm.Item>
);
const CaseAllocation = (props) => {
	const navigate = useNavigate();
	return (
		<>
			<HeaderFile activeNav="caseAllocation" />
			<XContainer fluid>
				<XCardsCaseBg>
					<Row>
						<Col xs={24} sm={12} lg={21} className="mb-4">
							<Space>
								<XcalendarBtn
									icon={
										<img
											src="/images/calendar.svg"
											alt=""
										/>
									}
									type="link"
									size="large"
									className="me-3"
								></XcalendarBtn>
								<XFilterInput
									addonBefore={prefixSelector}
									size="large"
									placeholder="Search"
								/>
							</Space>
						</Col>
						<Col xs={24} sm={12} lg={3} className="mb-4">
							<XButton2
								onClick={() => navigate('/case/add')}
								icon={<PlusOutlined />}
							>
								New Case
							</XButton2>
						</Col>
					</Row>
					<Row>
						<Col xs={24}>
							<XTabs
								defaultActiveKey="1"
								items={[
									{
										label: (
											<>
												All
												<span>500</span>
											</>
										),
										key: '1',
										children: <All />,
									},
									{
										label: (
											<>
												Completed Cases
												<span>100</span>
											</>
										),
										key: '2',
										children: <All />,
									},
									{
										label: (
											<>
												Pending Cases
												<span>200</span>
											</>
										),
										key: '3',
										children: <All />,
									},
									{
										label: (
											<>
												Re-allocate Cases
												<span>200</span>
											</>
										),
										key: '4',
										children: <All />,
									},
								]}
							/>
						</Col>
					</Row>
				</XCardsCaseBg>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAllocation);
