import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { Row, Col, Button, Input } from 'antd';
import { XCardsProfileBg, XAvatar, XUpload } from './ProfileStyle';
import { XRadio } from '../CaseForm/CaseFormStyle';

const props = {
	action: '//jsonplaceholder.typicode.com/posts/',
	listType: 'picture',
	previewFile(file) {
		console.log('Your upload file:', file);
		// Your process logic. Here we just mock to the same file
		return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
			method: 'POST',
			body: file,
		})
			.then((res) => res.json())
			.then(({ thumbnail }) => thumbnail);
	},
};

const Profile = (props) => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const [value, setValue] = useState(1);
	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};
	return (
		<>
			<HeaderFile />
			<XContainer fluid>
				<XCardsProfileBg>
					<div className="profileHead"></div>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
					>
						<div className="innerBody">
							<Row>
								<Col className="m-auto text-center">
									<XAvatar
										size={{
											xs: 100,
											sm: 100,
											md: 100,
											lg: 100,
											xl: 100,
											xxl: 100,
										}}
										icon={
											<img
												className="img-fluid"
												src="/images/prifile.svg"
												alt=""
											/>
										}
									/>
								</Col>
							</Row>
							<Row className="my-4">
								<Col className="m-auto text-center">
									<XUpload {...props}>
										<Button>Upload Profile Picture</Button>
									</XUpload>
								</Col>
							</Row>
							<Row gutter={(15, 30)}>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="first_name"
										label="First Name*"
									>
										<Input
											size="large"
											placeholder="Enter first name"
											name="first_name"
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="last_name"
										label="Last Name*"
									>
										<Input
											size="large"
											placeholder="Enter Last name"
											name="last_name"
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="mobile_number"
										label="Mobile Number*"
									>
										<Input
											size="large"
											placeholder="Enter mobile number"
											name="mobile_number"
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="email_address"
										label="Email Address*"
									>
										<Input
											size="large"
											placeholder="Enter email address"
											name="email_address"
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item name="gender" label="Gender*">
										<XRadio.Group
											onChange={onChange}
											value={value}
											name="gender"
										>
											<XRadio value={1}>Male</XRadio>
											<XRadio value={2}>Female</XRadio>
											<XRadio value={3}>Other</XRadio>
										</XRadio.Group>
									</XForm.Item>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col xs={24} className="text-lg-end">
									<Button
										onClick={() => navigate(-1)}
										className="cancel"
									>
										Cancel
									</Button>
									<Button type="primary">Submit</Button>
								</Col>
							</Row>
						</div>
					</XForm>
				</XCardsProfileBg>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
