import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button, Radio } from 'antd';

export const XCardBackBg = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XButtonBack = styled(Button)`
	color: ${({ theme }) => theme.colors.menu2};
	border-radius: 20px !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
	svg {
		font-size: 16px;
		font-weight: 600;
	}
	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XEditTitle = styled.span`
	margin-left: 15px;
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.36px;
`;
export const XRadio = styled(Radio)`
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;
