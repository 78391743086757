import React, { useState } from 'react';
import Login from './pages/Login/Login';
import { GlobalStyle, XContainer, Theme, XLayout } from './styles/GlobalStyle';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Page404 from './pages/Page404';
import PrivateRoute from './components/PrivateRoute';
import SignUp from './pages/SignUp/SignUp';
import { Layout } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Forgot from './pages/Forgot/Forgot';
import Otp from './pages/Otp/Otp';
import Reset from './pages/ResetPassword/Reset';
import CaseForm from './pages/CaseForm/CaseForm';
import Manage from './pages/Manage/Manage';
import Profile from './pages/Profile/Profile';
import CaseAllocation from './pages/CaseAllocation/CaseAllocation';
import CasePreparation from './pages/CasePreparation/CasePreparation';
import AnalyseCoverage from './pages/AnalyseCoverage/AnalyseCoverage';
import ResetAccount from './pages/ResetAccount/ResetAccount';

const { Header, Content, Footer } = Layout;

function App(props) {
	return (
		<ThemeProvider theme={Theme}>
			<GlobalStyle />
			<BrowserRouter>
				<XLayout>
					<Routes>
						<Route path="/login" element={<Login />}></Route>
						<Route path="/forgot" element={<Forgot />}></Route>
						<Route path="/reset" element={<Reset />}></Route>
						<Route path="/otp" element={<Otp />}></Route>
						<Route path="/signup" element={<SignUp />}></Route>
						<Route path="/" element={<Outlet />}>
							<Route
								exact
								path="/"
								element={<Dashboard />}
							></Route>
							<Route
								exact
								path="/case/add"
								element={<CaseForm />}
							></Route>
							<Route
								exact
								path="/case/edit/:id"
								element={<CaseForm />}
							></Route>

							<Route
								exact
								path="/manage"
								element={<Manage />}
							></Route>
							<Route
								exact
								path="/case-allocation"
								element={<CaseAllocation />}
							></Route>
							<Route
								exact
								path="/analyse-coverage"
								element={<AnalyseCoverage />}
							></Route>
							<Route
								exact
								path="/case-preparation"
								element={<CasePreparation />}
							></Route>
							<Route
								exact
								path="/profile"
								element={<Profile />}
							></Route>
							<Route
								exact
								path="/reset-account"
								element={<ResetAccount />}
							></Route>
						</Route>
						<Route path="/*" element={<Page404 />} />
					</Routes>
				</XLayout>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
