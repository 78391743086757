import { React } from 'react';
import { connect } from 'react-redux';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsTransparentTabs, XTabs } from './ManageStyle';
import TabPane from 'antd/es/tabs/TabPane';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import CaseManagement from './CaseManagement';
import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
import GroupManagement from './GroupManagement';

const Manage = (props) => {
	return (
		<>
			<HeaderFile activeNav="manage" />
			<XContainer fluid>
				{/* <XCardBackBg>items</XCardBackBg> */}
				<XCardsTransparentTabs>
					<XTabs defaultActiveKey="1" type="card">
						<TabPane key="1" tab="Case Management">
							<CaseSearch searchOption="default" />
							<CaseManagement />
						</TabPane>
						<TabPane key="2" tab="User Management">
							<CaseSearch searchOption="userManagement" />
							<UserManagement />
						</TabPane>
						<TabPane key="3" tab="Role Management">
							<CaseSearch searchOption="roleManagement" />
							<RoleManagement />
						</TabPane>
						<TabPane key="4" tab="Group Management">
							<CaseSearch searchOption="groupManagement" />
							<GroupManagement />
						</TabPane>
					</XTabs>
				</XCardsTransparentTabs>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
