import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
	CenterWrapper,
	Logo,
	Title,
	XForm,
	XCheckbox,
	XLottie,
} from './LoginStyle';
import { XContainer } from '../../styles/GlobalStyle';
import { Button, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';

const Login = (props) => {
	const [form] = XForm.useForm();
	const navigate = useNavigate();
	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Logo>
						<img
							className="img-fluid"
							src="./images/logo.svg"
							alt="logo"
						/>
					</Logo>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 10, order: 1 }}
					>
						<Title className="mb-4 mb-lg-5">
							Welcome to Medicare Coverage Analysis Platform
						</Title>
						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
						>
							<XForm.Item name="email" label="Email Address">
								<Input
									size="large"
									placeholder="Enter email address"
								/>
							</XForm.Item>
							<XForm.Item name="password" label="Password">
								<Input
									size="large"
									placeholder="Enter email address"
								/>
							</XForm.Item>
							<XForm.Item>
								<XForm.Item
									name="remember"
									valuePropName="checked"
									noStyle
								>
									<XCheckbox>Keep me signed in</XCheckbox>
								</XForm.Item>
								<Link
									className="login-form-forgot"
									to="/forgot"
								>
									Forgot email or password?
								</Link>
							</XForm.Item>

							<XForm.Item className="mt-5">
								<Button onClick={() => navigate('/')}>
									Log In
								</Button>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 4, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
