import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import DashboardJson from '../../json/Login.json';
import HeaderFile from '../../components/Header/HeaderFile';
import {
	XContainer,
	XButton,
	XCardsTransparent,
	XTable,
	XTag,
	XDropdown,
	XConfirmModal,
} from '../../styles/GlobalStyle';
import {
	XCardBg,
	XLottieWrapp,
	XLottie,
	Title,
	Title2,
	XCardData,
	XCardData2,
} from './DashboardStyle';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import {
	MoreOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';

const showDeleteConfirm = () => {
	XConfirmModal.confirm({
		title: 'Are you sure delete this case?',
		icon: <ExclamationCircleFilled />,
		//content: 'Some descriptions',
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'No',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const Dashboard = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const items = [
		{
			label: <Link to={'/case/edit/123'}>Edit</Link>,
			key: '1',
			icon: <EditOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: <Link onClick={showDeleteConfirm}>Delete</Link>,
			key: '2',
			icon: <DeleteOutlined />,
		},
	];

	const columns = [
		{
			title: 'CASE Name',
			dataIndex: 'casename',
		},
		{
			title: 'Allocated to',
			dataIndex: 'allocated',
		},
		{
			title: 'status',
			dataIndex: 'status',
		},
		{
			title: 'last date',
			dataIndex: 'lastdate',
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
		},
	];
	const data = [];
	for (let i = 1; i < 7; i++) {
		data.push({
			key: i,
			casename: `Robert Anthony`,
			allocated: `Amin Sayyed`,
			status: (
				<>
					<XTag className="completed">Completed</XTag>
				</>
			),
			lastdate: (
				<div className="showDate">
					<img
						className="img-fluid"
						src="./images/date-icon.svg"
						alt=""
					/>
					04 Oct 2023 , 18:00 PM
				</div>
			),
			action: (
				<div className="text-center">
					<XDropdown
						menu={{
							items,
						}}
						placement="bottom"
						arrow
						trigger={['click']}
					>
						<a onClick={(e) => e.preventDefault()}>
							<MoreOutlined />
						</a>
					</XDropdown>
				</div>
			),
		});
	}

	return (
		<>
			<HeaderFile activeNav="dashboard" />
			<XContainer fluid>
				<XCardBg className="mb-3">
					<Row gutter={(15, 15)} className="align-items-center">
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							lg={{ span: 6 }}
							className="mb-4  mb-lg-0"
						>
							<XLottieWrapp className="mb-4">
								<XLottie
									animationData={DashboardJson}
									loop={true}
								/>
							</XLottieWrapp>
							<div className="date mt-2">Monday,06</div>
							<Title>Good Morning Amin</Title>
							<Title2>
								Hi, you have received{' '}
								<span>10 Notifications</span>
							</Title2>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							lg={{ span: 6 }}
							className="mb-4 mb-lg-0"
						>
							<img
								className="img-fluid"
								src="./images/dahsboard-graph.png"
								alt=""
							/>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							lg={{ span: 6 }}
							className="mb-4 mb-sm-0 mb-lg-0"
						>
							<XCardData>
								<Row className="align-items-center">
									<Col xs={18}>
										<div className="subHeading">
											Total Cases
										</div>
										<div className="headingMain">20000</div>
									</Col>
									<Col xs={6} className="text-center">
										<img
											className="img-fluid"
											src="./images/total-cases.svg"
											alt=""
										/>
									</Col>
								</Row>
							</XCardData>
							<XCardData className="my-4">
								<Row className="align-items-center">
									<Col xs={18}>
										<div className="subHeading">
											Completed Cases
										</div>
										<div className="headingMain">20000</div>
									</Col>
									<Col xs={6} className="text-center">
										<img
											className="img-fluid"
											src="./images/complete-cases.svg"
											alt=""
										/>
									</Col>
								</Row>
							</XCardData>
							<XCardData>
								<Row className="align-items-center">
									<Col xs={18}>
										<div className="subHeading">
											Pending Cases
										</div>
										<div className="headingMain">20000</div>
									</Col>
									<Col xs={6} className="text-center">
										<img
											className="img-fluid"
											src="./images/pending-cases.svg"
											alt=""
										/>
									</Col>
								</Row>
							</XCardData>
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							lg={{ span: 6 }}
						>
							<XCardData2>
								<Row className="align-items-center">
									<Col xs={24} className="mb-4 text-center">
										<img
											className="img-fluid"
											src="./images/new-case.svg"
											alt=""
										/>
									</Col>
									<Col xs={24} className="text-center">
										<div className="mb-2 headingMain">
											Create New Case
										</div>
										<div className="subHeading my-4">
											Lorem ipsum dolor sit amet,
											consectetur adipiscing elit
										</div>
										<XButton>Update your security</XButton>
									</Col>
								</Row>
							</XCardData2>
						</Col>
					</Row>
				</XCardBg>
				<XCardsTransparent>
					<CaseSearch searchOption="default" />
					<XTable
						rowSelection={rowSelection}
						columns={columns}
						dataSource={data}
						scroll={{ x: 991 }}
						pagination={false}
					/>
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

export default Dashboard;
