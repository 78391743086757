import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Logo, Title, XForm, XLottie } from '../Login/LoginStyle';
import { CenterWrapper } from '../Forgot/ForgotStyle';
import { XPinInput, XPara1 } from './OtpStyle';
import { Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { XContainer } from '../../styles/GlobalStyle';

const Otp = (props) => {
	const [form] = XForm.useForm();
	const navigate = useNavigate();

	const onChangeInput = (event) => {
		let input = event.target.value;
		this.setState(
			{
				input: input,
			},
			() => {
				this.keyboard.setInput(input);
			},
		);
	};
	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Logo>
						<img
							className="img-fluid"
							src="./images/logo.svg"
							alt="logo"
						/>
					</Logo>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						<Title className="mb-3 mb-lg-2 text-center">
							OTP Verification
						</Title>
						<XPara1 className="mb-5">
							We sent a verification code to
							<span> amin@aiolos.cloud</span>
						</XPara1>
						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
						>
							<XForm.Item name="otp" className="text-center">
								<XPinInput
									focus="true"
									length={4}
									initialValue=""
									//secret="false"
									//secretDelay={100}
									onChange={(value, index) => {}}
									type="numeric"
									inputMode="number"
									style={{ padding: '0px' }}
									inputStyle={{
										borderColor: '#D8D8D7',
										background: '#F7F9FD',
										padding: '5px',
										width: '60px',
										height: '60px',
										fontSize: '2rem',
										marginRight: '15px',
									}}
									inputFocusStyle={{
										borderColor: '#D8D8D7',
										background: '#F7F9FD',
									}}
									onComplete={(value, index) => {}}
									autoSelect={true}
									regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
								/>
							</XForm.Item>

							<XForm.Item className="mt-5 text-center">
								<XPara1 className="mb-1">00:20</XPara1>
								<XPara1 className="mb-0">
									Don't Receive the OTP?{' '}
									<span>
										<Link>RESEND</Link>
									</span>
								</XPara1>
							</XForm.Item>

							<XForm.Item className="mt-5">
								<Button onClick={() => navigate('/reset')}>
									Submit
								</Button>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
