import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card } from 'antd';
import Lottie from 'lottie-react';

export const XCardBg = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 27px 34px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
		.date {
			color: ${({ theme }) => theme.colors.color1};
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media only screen and (max-width: 768.99px) {
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
	@media only screen and (min-width: 1920.99px) {
	}
`;
export const XLottieWrapp = styled.section`
	width: 100%;
	position: relative;
	div {
		position: relative;
		width: 150px;
		height: 150px;
		margin: -30px;
	}
	@media only screen and (max-width: 768.99px) {
	}
`;

export const XLottie = styled(Lottie)`
	position: relative;
	background: transparent;
	width: 100%;
`;
export const Title = styled.h1`
	position: relative;
	width: 100%;
	background: var(
		--new,
		linear-gradient(90deg, #69eacb 0%, #e2a3ff 48%, #6654f1 100%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
	font-size: 28px;
	line-height: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	@media only screen and (max-width: 768.99px) {
		font-size: 20px !important;
		line-height: 30px;
	}
`;
export const Title2 = styled.h2`
	position: relative;
	width: 100%;
	color: ${({ theme }) => theme.colors.color1};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 15px;
	span {
		color: ${({ theme }) => theme.colors.color2};
	}
	@media only screen and (max-width: 768.99px) {
		margin-top: 10px;
		font-size: 14px !important;
		line-height: 16px;
	}
`;
export const XCardData = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	.ant-card-body {
		padding: 15px !important;
		.subHeading {
			color: ${({ theme }) => theme.colors.color4};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.headingMain {
			color: ${({ theme }) => theme.colors.color2};
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
`;
export const XCardData2 = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	height: 100%;
	.ant-card-body {
		padding: 25px 30px !important;
		.subHeading {
			color: ${({ theme }) => theme.colors.color4};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.headingMain {
			color: ${({ theme }) => theme.colors.color2};
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
`;
