import { React, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { XTable, XModal, XForm, XTag, XSelect } from '../../styles/GlobalStyle';
import { XButtonImg, XAvatar } from './ManageStyle';
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';

const onChange = (key) => {
	console.log(key);
};
const showDeleteConfirm = () => {
	Modal.confirm({
		title: 'Are you sure delete this case?',
		icon: <ExclamationCircleFilled />,
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'No',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const GroupManagement = (props) => {
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const { Option } = XSelect;
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const columns = [
		{
			title: 'Group Name',
			dataIndex: 'groupName',
		},
		{
			title: 'Users',
			dataIndex: 'users',
		},
		{
			title: 'Created date',
			dataIndex: 'createdDate',
		},

		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			width: '10%',
		},
	];
	const data = [];
	for (let i = 1; i < 7; i++) {
		data.push({
			key: i,
			groupName: `Super Admin`,
			users: (
				<>
					<XAvatar.Group
						maxCount={2}
						maxStyle={{
							color: '#FFFFFF',
							backgroundColor: '#000000',
							outline: '1px solid #FFF',
						}}
					>
						<XAvatar src="/images/username-icon.png" />
						<XAvatar src="/images/username-icon-2.png" />
						<XAvatar src="/images/username-icon-3.png" />
					</XAvatar.Group>
				</>
			),
			createdDate: `04 Oct 2023, 12:00 PM`,
			action: (
				<div className="text-center">
					<Row>
						<Col xs={12}>
							<XButtonImg
								onClick={() => setModalOpen(true)}
								type="text"
							>
								<EditOutlined />
							</XButtonImg>
						</Col>
						<Col xs={12}>
							<XButtonImg onClick={showDeleteConfirm} type="text">
								<DeleteOutlined />
							</XButtonImg>
						</Col>
					</Row>
				</div>
			),
		});
	}
	return (
		<>
			<XTable
				rowSelection={rowSelection}
				columns={columns}
				dataSource={data}
				scroll={{ x: 991 }}
				pagination={false}
			/>
			<XModal
				title="Edit Group Name"
				centered
				open={modalOpen}
				onOk={() => setModalOpen(false)}
				onCancel={() => setModalOpen(false)}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<XForm.Item name="group_name" label="Group Name*">
								<Input
									size="large"
									placeholder="Enter group name"
									name="group_name"
								/>
							</XForm.Item>
						</Col>
						<Col xs={24}>
							<XForm.Item
								name="select_user"
								label="Select Users*"
							>
								<XSelect size="large" defaultValue="0">
									<Option value="0">Select</Option>
									<Option value="1">User Group 1</Option>
									<Option value="2">User Group 2</Option>
									<Option value="3">User Group 3</Option>
									<Option value="4">User Group 4</Option>
								</XSelect>
							</XForm.Item>
						</Col>
						<Col xs={24}>
							<XForm.Item
								name="select_group"
								label="Select Group*"
							>
								<XSelect size="large" defaultValue="0">
									<Option value="0">Select</Option>
									<Option value="1">Group 1</Option>
									<Option value="2">Group 2</Option>
									<Option value="3">Group 3</Option>
									<Option value="4">Group 4</Option>
								</XSelect>
							</XForm.Item>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<Button
								onCancel={() => setModalOpen(false)}
								className="modalButton"
							>
								Submit
							</Button>
						</Col>
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagement);
